<template>
  <div id="users">
    <el-breadcrumb separator="/" style="padding-left:10px;padding-bottom:10px;font-size:12px;">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>项目报表</el-breadcrumb-item>
      <el-breadcrumb-item>报表设置</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 用户列表卡片区 -->
    <el-card class="box-card">
      <div style="margin-left: 30px">
        <el-form :inline="true" ref="form" :model="queryMap" label-width="90px" size="small">
          <el-form-item label="请选择公司">
            <el-select
                v-model="queryMap.projectId"
                filterable
                placeholder="请选择公司"
                @change="confirm"
            >
              <el-option
                  v-for="organ in projectOptions"
                  :key="organ.id"
                  :label="organ.name"
                  :value="organ.id"
              ></el-option>
            </el-select>
          </el-form-item>

        </el-form>

        <!-- 表格区域 -->
        <el-table size="mini" :data="dataList" border style="width: 100%; margin-top: 20px" :span-method="mergeCells"
                  v-if="projectName" highlight-current-row="true">
          <el-table-column prop="group" label="类型" width="110">
            <template slot-scope="scope">
              <el-tag type="success">{{ scope.row.group }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="report" label="子类型" width="200"></el-table-column>
          <el-table-column label="操作" width="120">
            <template slot-scope="scope">
              <el-button type="primary" size="mini" @click="add(scope.row)">添加</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="projectName" label="签约项目" width="300" show-overflow-tooltip>
            <template scope="scope">
              <el-button type="text" size="mini" @click="editFunc(scope.row)">{{ scope.row.projectName }}</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="pindu" label="频度" width="150" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="shidian" label="时点" width="110">
          </el-table-column>

        </el-table>

        <el-drawer
            :title="row.report ? row.group + '_' + row.report : '无'"
            :visible.sync="drawer"
            @close="closeDrawer"
            size="40%" style="padding-left: 30px;" custom-class="demo-drawer">
          <div class="demo-drawer__content">
            <el-form :inline="true" ref="form" :model="queryMap" label-width="90px" size="small">
              <el-form-item label="签约项目:" v-if="drawerType == 1">
                <div style="color: red">{{ row.projectName }}</div>
              </el-form-item>

              <el-form-item label="类型: " v-if="drawerType == 0">
                <div style="color: red">{{ row.group }}</div>
              </el-form-item>
              <el-form-item label="子类型: " v-if="drawerType == 0">
                <div style="color: red">{{ row.report }}</div>
              </el-form-item>

              <el-row style="margin-top: 30px;">
                <el-form-item label="签约项目:" v-if="drawerType == 0">
                  <el-cascader
                      v-model="queryMap.subProjectId"
                      :options="options"
                      :props="{ checkStrictly: true }"
                      clearable></el-cascader>
                </el-form-item>
              </el-row>
            </el-form>

              <el-row style="margin-top: 30px" v-if="queryMap.type == 2">
                <!--                <el-card class="box-card" style="width: 500px; margin-top: 60px" >-->
                <!--                  <div slot="header" class="clearfix">-->
                <!--                    <span>工数记入提醒</span>-->
                <!--                  </div>-->
                <el-form ref="form" :model="settings.group_dailyprompt" label-width="90px">
                  <el-form-item label="频度">
                    <el-select
                        clearable
                        multiple
                        v-model="settings.group_dailyprompt.daysOfWeek"
                        placeholder="请选择频度">
                      <el-option
                          v-for="organ in pOptions"
                          :key="organ.id"
                          :label="organ.name"
                          :value="organ.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="时点">
                    <el-time-picker
                        editable
                        v-model="settings.group_dailyprompt.sendTime"
                        :picker-options="{
                    selectableRange: '06:00:00 - 20:30:00'
                    }"
                        placeholder="请选择时点">
                    </el-time-picker>
                  </el-form-item>
                  <el-form-item label="不受信邮箱">
                    <el-input
                        type="textarea"
                        placeholder="请输入不受信邮箱(换行分割)"
                        v-model="settings.group_dailyprompt.mails"
                        show-word-limit
                        rows="5"
                    >
                    </el-input>
                  </el-form-item>
                </el-form>
                <!--                </el-card>-->
              </el-row>

              <el-row style="margin-top: 20px" v-if="queryMap.type == 3 || queryMap.type == 4 ||queryMap.type == 11 ">
                <el-col v-if="queryMap.type == 3">
                  <!--                  <el-card class="box-card" style="width: 500px;">-->
                  <!--                    <div slot="header" class="clearfix">-->
                  <!--                      <span>组织工数日报</span>-->
                  <!--                    </div>-->
                  <el-form ref="form" :model="settings.group_spent_time_everyday" label-width="90px"
                  >
                    <el-form-item label="频度">
                      <el-select
                          clearable
                          multiple
                          v-model="settings.group_spent_time_everyday.daysOfWeek"
                          placeholder="请选择频度">
                        <el-option
                            v-for="organ in pOptions"
                            :key="organ.id"
                            :label="organ.name"
                            :value="organ.id"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="时点">
                      <el-time-picker
                          editable
                          v-model="settings.group_spent_time_everyday.sendTime"
                          :picker-options="{
                    selectableRange: '06:00:00 - 20:30:00'
                    }"
                          placeholder="请选择时点">
                      </el-time-picker>
                    </el-form-item>
                    <el-form-item label="受信邮箱">
                      <el-input
                          type="textarea"
                          placeholder="请输入受信邮箱(换行分割)"
                          v-model="settings.group_spent_time_everyday.mails"
                          show-word-limit
                          rows="5"
                      >
                      </el-input>
                    </el-form-item>
                    <el-form-item label="不统计成员">
                      <el-select
                          clearable
                          multiple
                          v-model="settings.group_spent_time_everyday.excludeMember"
                          placeholder="请选择不统计成员">
                        <el-option
                            v-for="organ in ruserOptions"
                            :key="organ.id"
                            :label="organ.lastname"
                            :value="organ.id"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="统计项目">
                      <el-select
                          clearable
                          multiple
                          v-model="target_prjects"
                          placeholder="请选择统计项目">
                        <el-option
                            v-for="organ in subProjectOptions"
                            :key="organ.id"
                            :label="organ.name"
                            :value="organ.id"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-form>
                  <!--                  </el-card>-->
                </el-col>
                <el-col v-if="queryMap.type == 4">
                  <!--                  <el-card class="box-card" style="width: 500px;">-->
                  <!--                    <div slot="header" class="clearfix">-->
                  <!--                      <span>组织工数周报</span>-->
                  <!--                    </div>-->
                  <el-form ref="form" :model="settings.group_spent_time_weekly" label-width="90px">
                    <el-form-item label="频度">
                      <el-select
                          clearable
                          multiple
                          v-model="settings.group_spent_time_weekly.daysOfWeek"
                          placeholder="请选择频度">
                        <el-option
                            v-for="organ in pOptions"
                            :key="organ.id"
                            :label="organ.name"
                            :value="organ.id"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="时点">
                      <el-time-picker
                          editable
                          v-model="settings.group_spent_time_weekly.sendTime"
                          :picker-options="{
                    selectableRange: '06:00:00 - 20:30:00'
                    }"
                          placeholder="请选择时点">
                      </el-time-picker>
                    </el-form-item>
                    <el-form-item label="受信邮箱">
                      <el-input
                          type="textarea"
                          placeholder="请输入受信邮箱(换行分割)"
                          v-model="settings.group_spent_time_weekly.mails"
                          show-word-limit
                          rows="5"
                      >
                      </el-input>
                    </el-form-item>
                    <el-form-item label="不统计成员">
                      <el-select
                          clearable
                          multiple
                          v-model="settings.group_spent_time_weekly.excludeMember"
                          placeholder="请选择不统计成员">
                        <el-option
                            v-for="organ in ruserOptions"
                            :key="organ.id"
                            :label="organ.lastname"
                            :value="organ.id"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="统计项目">
                      <el-select
                          clearable
                          multiple
                          v-model="target_prjects"
                          placeholder="请选择统计项目">
                        <el-option
                            v-for="organ in subProjectOptions"
                            :key="organ.id"
                            :label="organ.name"
                            :value="organ.id"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-form>
                  <!--                  </el-card>-->
                </el-col>

                <el-col v-if="queryMap.type == 11">
                  <!--                 <el-card class="box-card" style="width: 500px;">-->
                  <!--                   <div slot="header" class="clearfix">-->
                  <!--                     <span>组织工数页面设置</span>-->
                  <!--                   </div>-->
                  <el-form ref="form" :model="settings" label-width="90px">
                    <el-form-item label="统计项目">
                      <el-select
                          clearable
                          multiple
                          v-model="target_prjects"
                          placeholder="请选择统计项目">
                        <el-option
                            v-for="organ in subProjectOptions"
                            :key="organ.id"
                            :label="organ.name"
                            :value="organ.id"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-form>
                  <!--                 </el-card>-->
                </el-col>
              </el-row>

              <el-row style="margin-top: 30px" v-if="queryMap.type == 5 || queryMap.type == 6">
                <el-col v-if="queryMap.type == 5">
                  <!--                  <el-card class="box-card" style="width: 500px;">-->
                  <!--                    <div slot="header" class="clearfix">-->
                  <!--                      <span>任务预定开始通知</span>-->
                  <!--                    </div>-->
                  <el-form ref="form" :model="settings.PJ_duration_StartDay" label-width="90px">
                    <el-form-item label="频度">
                      <el-select
                          clearable
                          multiple
                          v-model="settings.PJ_duration_StartDay.daysOfWeek"
                          placeholder="请选择频度">
                        <el-option
                            v-for="organ in pOptions"
                            :key="organ.id"
                            :label="organ.name"
                            :value="organ.id"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="时点">
                      <el-time-picker
                          editable
                          v-model="settings.PJ_duration_StartDay.sendTime"
                          :picker-options="{
                    selectableRange: '06:00:00 - 20:30:00'
                    }"
                          placeholder="请选择时点">
                      </el-time-picker>
                    </el-form-item>
                  </el-form>
                  <!--                  </el-card>-->
                </el-col>

                <el-col v-if="queryMap.type == 6">
                  <!--                  <el-card class="box-card" style="width: 500px;">-->
                  <!--                    <div slot="header" class="clearfix">-->
                  <!--                      <span>任务预定完了通知</span>-->
                  <!--                    </div>-->
                  <el-form ref="form" :model="settings.PJ_duration_EndDay" label-width="90px">
                    <el-form-item label="频度">
                      <el-select
                          clearable
                          multiple
                          v-model="settings.PJ_duration_EndDay.daysOfWeek"
                          placeholder="请选择频度">
                        <el-option
                            v-for="organ in pOptions"
                            :key="organ.id"
                            :label="organ.name"
                            :value="organ.id"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="时点">
                      <el-time-picker
                          editable
                          v-model="settings.PJ_duration_EndDay.sendTime"
                          :picker-options="{
                    selectableRange: '06:00:00 - 20:30:00'
                    }"
                          placeholder="请选择时点">
                      </el-time-picker>
                    </el-form-item>
                  </el-form>
                  <!--                  </el-card>-->
                </el-col>

              </el-row>


              <el-row style="margin-top: 20px" v-if="queryMap.type == 7">
                <el-col>
                  <!--                  <el-card class="box-card" style="width: 500px;">-->
                  <!--                    <div slot="header" class="clearfix">-->
                  <!--                      <span>前一周的项目进度周报</span>-->
                  <!--                    </div>-->
                  <el-form ref="form" :model="settings.PJ_week_report_weekly" label-width="90px">
                    <el-form-item label="频度">
                      <el-select
                          clearable
                          multiple
                          v-model="settings.PJ_week_report_weekly.daysOfWeek"
                          placeholder="请选择频度">
                        <el-option
                            v-for="organ in pOptions"
                            :key="organ.id"
                            :label="organ.name"
                            :value="organ.id"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="时点">
                      <el-time-picker
                          editable
                          v-model="settings.PJ_week_report_weekly.sendTime"
                          :picker-options="{
                    selectableRange: '06:00:00 - 20:30:00'
                    }"
                          placeholder="请选择时点">
                      </el-time-picker>
                    </el-form-item>
                  </el-form>
                  <!--                  </el-card>-->
                </el-col>
              </el-row>
            <div class="demo-drawer__footer" style="margin-left: 20px">
              <!--                    <el-button class="custom-font" type="primary" @click="projectChange()">选择</el-button>-->
              <el-button  v-if="row.edit == 1" class="custom-font" type="success" @click="confirm2()">保存</el-button>

              <!--                    <el-button class="custom-font" type="primary" @click="projectChange()">选择</el-button>-->
              <el-button v-if="drawerType == 1" class="custom-font" type="danger" @click="confirm2()">删除</el-button>
            </div>
          </div>

          <!--                <div style="margin-left: 30px" v-if="typeName2 === '页面'">-->
          <!--                  <el-row style="margin-top: 20px">-->
          <!--                    <el-col :span="8">-->
          <!--                      <el-radio v-model="checked2" label="1" border>看板</el-radio>-->
          <!--                    </el-col>-->
          <!--                  </el-row>-->
          <!--                  <el-row style="margin-top: 20px">-->
          <!--                    <el-col :span="24">-->
          <!--                      <el-radio v-model="checked2" label="2" border>查看工数数据(T2)</el-radio>-->
          <!--                    </el-col>-->
          <!--                    <el-card class="box-card" style="width: 500px; margin-top: 60px"  v-if="checked2 === '2'">-->
          <!--                      <div slot="header" class="clearfix">-->
          <!--                        <span>统计项目设置</span>-->
          <!--                      </div>-->
          <!--                      <el-form ref="form" :model="settings" label-width="90px">-->
          <!--                        <el-form-item label="统计项目">-->
          <!--                          <el-select-->
          <!--                              clearable-->
          <!--                              multiple-->
          <!--                              v-model="target_prjects"-->
          <!--                              placeholder="请选择统计项目">-->
          <!--                            <el-option-->
          <!--                                v-for="organ in subProjectOptions"-->
          <!--                                :key="organ.id"-->
          <!--                                :label="organ.name"-->
          <!--                                :value="organ.id"-->
          <!--                            ></el-option>-->
          <!--                          </el-select>-->
          <!--                        </el-form-item>-->
          <!--                        <el-form-item label="不统计成员">-->
          <!--                          <el-select-->
          <!--                              clearable-->
          <!--                              multiple-->
          <!--                              v-model="excludeMember"-->
          <!--                              placeholder="请选择不统计成员">-->
          <!--                            <el-option-->
          <!--                                v-for="organ in ruserOptions"-->
          <!--                                :key="organ.id"-->
          <!--                                :label="organ.lastname"-->
          <!--                                :value="organ.id"-->
          <!--                            ></el-option>-->
          <!--                          </el-select>-->
          <!--                        </el-form-item>-->
          <!--                      </el-form>-->
          <!--                    </el-card>-->
          <!--                  </el-row>-->
          <!--                  <el-row style="margin-top: 20px">-->

          <!--                    <el-col :span="12">-->
          <!--                      <el-radio v-model="checked2" label="3" border>查看当天开始任务(T3)</el-radio>-->
          <!--                    </el-col>-->

          <!--                  </el-row>-->
          <!--                  <el-row style="margin-top: 20px">-->
          <!--                    <el-col :span="24">-->
          <!--                      <el-radio v-model="checked2" label="4" border>查看当天结束任务(T3)</el-radio>-->
          <!--                    </el-col>-->
          <!--                  </el-row>-->
          <!--                  <el-row style="margin-top: 20px">-->
          <!--                    <el-col :span="24">-->
          <!--                      <el-radio v-model="checked2" label="5" border>查看周报(T4)</el-radio>-->
          <!--                    </el-col>-->
          <!--                  </el-row>-->
          <!--                </div>-->

        </el-drawer>


      </div>

    </el-card>
  </div>

</template>

<script>
export default {
  name: "setupInfo",
  data() {
    return {
      activeNames: ['1', '2', '3', '4', '5'],
      queryMap: {
        currentPage: 1,
        pageSize: 10
      },
      typeName2: '',
      addStatus: false,
      shortName: "",
      dataList: [
        {
          pindu: '-',
          shidian: '-',
          edit: 0,
          projectName: 'MiniVRF横长S系列 4~6HP室外机',
          type: "页面",
          group: '数据看板',
          reportType: "redminex",
          report: "-",
          typenum: 1
        },
        {
          pindu: '-',
          shidian: '-',
          edit: 0,
          projectName: '先行开发PJ(恒温恒湿VRF室内外机)',
          type: "页面",
          group: '数据看板',
          reportType: "redminex",
          report: "-",
          typenum: 1
        },
        {
          pindu: '周一',
          shidian: '13:00:00',
          edit: 1,
          projectName: '其他开发',
          type: "报表",
          group: 'T1',
          reportType: "group_dailyprompt",
          report: "提醒",
          typenum: 2
        },
        {
          pindu: '周一',
          shidian: '13:00:00',
          edit: 1,
          projectName: '欧州向VRF超薄管道机',
          type: "报表",
          group: 'T1',
          reportType: "group_dailyprompt",
          report: "提醒",
          typenum: 2
        },
        {
          pindu: '-',
          shidian: '-',
          edit: 1,
          projectName: 'VRF 52° 1级6.5~9HP室外机三相',
          type: "报表",
          group: 'T2',
          reportType: "group_spent_time",
          report: "项目工数(页面)",
          typenum: 11
        },
        {
          pindu: '周一',
          shidian: '13:00:00',
          edit: 1,
          projectName: 'VRF 52° 1级6.5~9HP室外机三相',
          type: "报表",
          group: 'T2',
          reportType: "group_spent_time_everyday",
          report: "项目工数日报",
          typenum: 3
        },
        {
          pindu: '周一',
          shidian: '13:00:00',
          edit: 1,
          projectName: 'VRF 52° 1级6.5~9HP室外机三相',
          type: "报表",
          group: 'T2',
          reportType: "group_spent_time_weekly",
          report: "项目工数周报",
          typenum: 4
        },
        {
          pindu: '-',
          shidian: '-',
          edit: 0,
          projectName: '部门共通2023',
          type: "报表",
          group: 'T3',
          reportType: "PJ_duration_StartDay_page",
          report: "任务预定开始(页面)",
          typenum: 10
        },
        {
          pindu: '-',
          shidian: '-',
          edit: 0,
          projectName: '部门共通2023',
          type: "报表",
          group: 'T3',
          reportType: "PJ_duration_EndDay_page",
          report: "任务预定结束(页面)",
          typenum: 10
        },
        {
          pindu: '周二',
          shidian: '13:00:00',
          edit: 1,
          projectName: 'VRF 52° 1级6.5~9HP室外机三相',
          type: "报表",
          group: 'T3',
          reportType: "PJ_duration_StartDay",
          report: "任务预定开始通知",
          typenum: 5
        },
        {
          pindu: '周二',
          shidian: '13:00:00',
          edit: 1,
          projectName: 'VRF 52° 1级6.5~9HP室外机三相',
          type: "报表",
          group: 'T3',
          reportType: "PJ_duration_EndDay",
          report: "任务预定结束通知",
          typenum: 6
        },
        {
          pindu: '',
          shidian: '',
          edit: 0,
          projectName: '',
          type: "",
          group: 'T4',
          reportType: "PJ_week_report_weekly_page",
          report: "项目进度周报(页面)",
          typenum: 10
        },
        {
          pindu: '',
          shidian: '',
          edit: 1,
          projectName: '',
          type: "",
          group: 'T4',
          reportType: "PJ_week_report_weekly",
          report: "前一周的项目进度周报",
          typenum: 7
        },
      ],
      dataList2: [
        {projectName: '子项目1', type: "报表", reportType: "redminex"},
      ],
      projectOptions: [
        {id: 1, name: "大型空调", type: 0},
        {id: 2, name: "TW项目", type: 1},
        {id: 3, name: "LY项目", type: 0},
      ],
      drawer: false,
      options: [{
        value: '项目1',
        label: '项目1',
        children: [{
          value: '项目2',
          label: '项目2',
          children: [{
            value: '项目3',
            label: '项目3'
          }, {
            value: '项目4',
            label: '项目4'
          }, {
            value: '项目5',
            label: '项目5'
          }, {
            value: '项目6',
            label: '项目6'
          }]
        }]
      }],
      typeOptions: [
        {id: 1, name: "Redmine_X数据看板"},
        {id: 2, name: "工数记入提醒"},
        {id: 3, name: "项目工数日报(T2)"},
        {id: 4, name: "项目工数周报(T2)"},
        {id: 5, name: "任务预定开始通知(T3)"},
        {id: 6, name: "任务预定结束通知(T3)"},
        {id: 7, name: "前一周的项目进度周报(T4)"},
      ],
      checked1: '',
      checked2: '',
      typeName: '',
      settings: {
        group_spent_time_everyday: {},
        group_spent_time_weekly: {},
        group_spent_time: {},
        PJ_duration_StartDay: {},
        PJ_duration_EndDay: {},
        PJ_week_report_weekly: {},
        group_dailyprompt: {}
      },
      pOptions: [
        {id: 1, name: "周日"},
        {id: 2, name: "周一"},
        {id: 3, name: "周二"},
        {id: 4, name: "周三"},
        {id: 5, name: "周四"},
        {id: 6, name: "周五"},
        {id: 7, name: "周六"},
      ],
      ruserOptions: [
        {id: 1, lastName: "张三"},
        {id: 2, lastName: "李四"},
        {id: 3, lastName: "王五"},
      ],
      addOrEdit: '',
      projectName: "",
      target_prjects: [],
      subProjectOptions: [
        {id: 1, name: "下级项目"},
        {id: 2, name: "下级项目1"},
        {id: 3, name: "下级项目2"},
        {id: 4, name: "下级项目3"},
        {id: 5, name: "下级项目4"},
        {id: 6, name: "下级项目5"},
      ],

      projectName2: "",
      drawerType: 0,
      row: {type: ''},
    }
  },
  created() {
    this.setrowspans()
  },
  methods: {
    mergeCells({row, column, rowIndex, columnIndex}) {
      if (columnIndex === 0) {
        return {
          rowspan: row.rowspan,
          colspan: 1
        };
      } else if (columnIndex === 1) {
        return {
          rowspan: row.row2span,
          colspan: 1
        };
      } else if (columnIndex === 2) {
        return {
          rowspan: row.row2span,
          colspan: 1
        };
      }
    },
    setrowspans() {
      // 先给所有的数据都加一个v.rowspan = 1
      this.dataList.forEach(v => {
        v.rowspan = 1;
        v.row2span = 1;
      });
      // 双层循环
      for (let i = 0; i < this.dataList.length; i++) {
        // 内层循环，上面已经给所有的行都加了v.rowspan = 1
        // 这里进行判断
        // 如果当前行的id和下一行的id相等
        // 就把当前v.rowspan + 1
        // 下一行的v.rowspan - 1
        for (let j = i + 1; j < this.dataList.length; j++) {
          //此处可根据相同字段进行合并，此处是根据的id
          if (this.dataList[i].group === this.dataList[j].group) {
            this.dataList[i].rowspan++;
            this.dataList[j].rowspan--;
          }
          if (this.dataList[i].reportType === this.dataList[j].reportType) {
            this.dataList[i].row2span++;
            this.dataList[j].row2span--;
          }
        }
        // 这里跳过已经重复的数据
        i = i + this.dataList[i].rowspan - 1;
      }
    },
    closeDrawer() {
      this.queryMap.subProjectId = ''
      this.queryMap.type = ''
      this.typeName2 = ''
    },
    add(row) {
      if (!this.projectName) {
        this.$message.warning('请选择公司');
        return
      }
      this.drawer = true
      this.drawerType = 0
      this.row = row
      this.queryMap.type = row.typenum
    },
    editFunc(row) {
        this.drawer = true
        this.drawerType = 1
        this.row = row
        this.typeName2 = row.report

        this.queryMap.type = row.typenum

    },
    del() {

    },
    getSubReportSetting() {
      for (let index = 0; index < this.subProjectOptions.length; index++) {
        if (this.queryMap.subProjectId == this.subProjectOptions[index].id) {
          this.projectName2 = this.subProjectOptions[index].name
        }
      }
    },

    /**
     * 确定按钮
     */
    async confirm() {
      for (let index = 0; index < this.projectOptions.length; index++) {
        if (this.queryMap.projectId == this.projectOptions[index].id) {
          this.projectName = this.projectOptions[index].name
        }
      }
      if (this.queryMap.projectId === 2) {
        this.typeName = "兼容类型"
      } else {
        this.typeName = "标准类型"
      }
    },
    confirm2() {

    },
    /**
     * 项目切换
     */
    projectChange() {
      this.subProjectName = this.queryMap.projectId[0]
      for (let i = 0; i < this.typeOptions.length; i++) {
        if (this.queryMap.type == this.typeOptions[i].id) {
          this.typeName2 = this.typeOptions[i].name
        }
      }
      this.getProjectMember()
    },
    /**
     * 获取项目列表
     * @returns {Promise<void>}
     */
    async getProjects() {
      const {data: res} = await this.$http.get("/system/projects");
      this.projectOptions = res.data
      if (res.data.length >= 1) {
        this.queryMap.projectId = res.data[0].id
        this.getProjectMember()
      }
    },

    /**
     * 获取项目下成员
     * @returns {Promise<void>}
     */
    async getProjectMember() {
      const {data: res} = await this.$http.get("/system/" + this.queryMap.projectId + "/members");
      this.ruserOptions = res.data
    },

    /**
     * 时间处理
     * @param time
     * @returns {Date}
     */
    timeAsDate(time) {
      const parts = time.split(':');
      const hours = parseInt(parts[0], 10);
      const minutes = parseInt(parts[1], 10);
      const seconds = parseInt(parts[2], 10);
      // 创建一个新的Date对象，设置为今天的日期，并设置指定的时、分、秒
      return new Date(new Date().setHours(hours, minutes, seconds, 0));
    }


  }
}
</script>

<style scoped>
/deep/ .el-collapse-item__header {
  font-size: 20px !important;
  color: red;
}
</style>